const isDevelopment = process.env.NODE_ENV === "development";
const stagingPreviewsOrigin = "tv-management-uat.pages.dev";
const productionPreviewsOrigin = "tv-management-app.pages.dev";
const isStaging = window.location.host.endsWith(stagingPreviewsOrigin);

export const config = {
  isDevelopment,
  isStaging,
  stagingPreviewsOrigin,
  productionPreviewsOrigin,
  oktaIssuerUrl:
    isStaging || isDevelopment
      ? "https://login.teamworksuat.com/oauth2/default/"
      : "https://login.teamworksapp.com/oauth2/default/",
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID ?? "0oad6p3dssYiqakRH1d7",
  environment: process.env.NODE_ENV ?? "development",
  apiBaseUrls: isDevelopment
    ? [
        { region: "us", url: "http://localhost:9000" },
        { region: "eu", url: "http://localhost:9000" },
        { region: "ap", url: "http://localhost:9000" },
      ]
    : isStaging
      ? [
          { region: "us", url: "https://api.us.staging.displays.tw" },
          { region: "eu", url: "https://api.eu.staging.displays.tw" },
          { region: "ap", url: "https://api.apac.staging.displays.tw" },
        ]
      : [
          { region: "us", url: "https://api.us.displays.tw" },
          { region: "eu", url: "https://api.eu.displays.tw" },
          { region: "ap", url: "https://api.apac.displays.tw" },
        ],
  tvAppUrl: isDevelopment ? "http://localhost:8081" : "https://displays.tw",
  cmsAppUrl: isDevelopment
    ? "http://localhost:3000"
    : "https://app.displays.tw",
  customerSupportLink: "https://teamworkshelpcenter.zendesk.com/",
  mapBoxAccessToken:
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ??
    "pk.eyJ1IjoiZGNoa2Fpcm9zIiwiYSI6ImNrcWpqYXYweDAzMG4yeG5zOXg3YjRmcHQifQ.pHjl0lurVo7vu1sbljSwQQ",
  posthog: {
    enabled: false,
    apiKey: (process.env.POSTHOG_API_KEY as string) ?? "",
    hostname: (process.env.POSTHOG_URL as string) ?? "https://us.i.posthog.com",
  },
};
