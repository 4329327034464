/**
 * @generated SignedSource<<4c696c02a204b45e08d2b81e4a1a1567>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceScheduleScreen_InitMutation$variables = {
  screenDeviceId: string;
};
export type DeviceScheduleScreen_InitMutation$data = {
  readonly createDeviceSchedule: {
    readonly screenDevice: {
      readonly deviceSchedule: {
        readonly id: string;
      } | null | undefined;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"DeviceScheduleScreen_DeviceFragment">;
    };
  };
};
export type DeviceScheduleScreen_InitMutation = {
  response: DeviceScheduleScreen_InitMutation$data;
  variables: DeviceScheduleScreen_InitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "screenDeviceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "screenDeviceId",
    "variableName": "screenDeviceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceSchedule",
  "kind": "LinkedField",
  "name": "deviceSchedule",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceScheduleScreen_InitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeviceSchedulePayload",
        "kind": "LinkedField",
        "name": "createDeviceSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenDevice",
            "kind": "LinkedField",
            "name": "screenDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeviceScheduleScreen_DeviceFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceScheduleScreen_InitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDeviceSchedulePayload",
        "kind": "LinkedField",
        "name": "createDeviceSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScreenDevice",
            "kind": "LinkedField",
            "name": "screenDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeZone",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "883fed03f5064c92149acb74605b0699",
    "id": null,
    "metadata": {},
    "name": "DeviceScheduleScreen_InitMutation",
    "operationKind": "mutation",
    "text": "mutation DeviceScheduleScreen_InitMutation(\n  $screenDeviceId: ID!\n) {\n  createDeviceSchedule(screenDeviceId: $screenDeviceId) {\n    screenDevice {\n      id\n      deviceSchedule {\n        id\n      }\n      ...DeviceScheduleScreen_DeviceFragment\n    }\n  }\n}\n\nfragment DeviceScheduleScreen_DeviceFragment on ScreenDevice {\n  id\n  deviceName\n  timeZone\n  deviceSchedule {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "06d65faf9ca41c1a89aef19650e96853";

export default node;
