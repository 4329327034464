/**
 * @generated SignedSource<<a53b46cad7cd81502bfa01438f859cdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ScheduleConflictChecker_ConflictCheckerQuery$variables = {
  deviceScheduleId: string;
  excludedDeviceScheduleItemIds?: ReadonlyArray<string> | null | undefined;
  from: string;
  to: string;
};
export type ScheduleConflictChecker_ConflictCheckerQuery$data = {
  readonly node: {
    readonly __typename: "DeviceSchedule";
    readonly deviceScheduleItems: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type ScheduleConflictChecker_ConflictCheckerQuery = {
  response: ScheduleConflictChecker_ConflictCheckerQuery$data;
  variables: ScheduleConflictChecker_ConflictCheckerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceScheduleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludedDeviceScheduleItemIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceScheduleId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "excludedDeviceScheduleItemIds",
          "variableName": "excludedDeviceScheduleItemIds"
        },
        {
          "kind": "Variable",
          "name": "from",
          "variableName": "from"
        },
        {
          "kind": "Variable",
          "name": "to",
          "variableName": "to"
        }
      ],
      "concreteType": "DeviceScheduleItemConnection",
      "kind": "LinkedField",
      "name": "deviceScheduleItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceScheduleItem",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceSchedule",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleConflictChecker_ConflictCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScheduleConflictChecker_ConflictCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88160bd3bf9b41c72ce6ef8f129863f7",
    "id": null,
    "metadata": {},
    "name": "ScheduleConflictChecker_ConflictCheckerQuery",
    "operationKind": "query",
    "text": "query ScheduleConflictChecker_ConflictCheckerQuery(\n  $deviceScheduleId: ID!\n  $from: ISO8601DateTime!\n  $to: ISO8601DateTime!\n  $excludedDeviceScheduleItemIds: [ID!]\n) {\n  node(id: $deviceScheduleId) {\n    __typename\n    ... on DeviceSchedule {\n      deviceScheduleItems(from: $from, to: $to, excludedDeviceScheduleItemIds: $excludedDeviceScheduleItemIds) {\n        nodes {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0936278abef17c41f638e49a4079a6b7";

export default node;
