import React, { useEffect, useRef } from "react";
import { Button } from "./Button";
import { TableCell, TableRow } from "./Table";
import { first } from "lodash";
import { MenuItem } from "@mui/material";

type InfiniteScrollProps = {
  hasNext: boolean;
  isLoadingNext: boolean;
  loadNext: () => void;
};

const InfiniteScroll = ({
  hasNext,
  isLoadingNext,
  loadNext,
}: InfiniteScrollProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!hasNext || isLoadingNext || ref.current == null) return;

    const observer = new IntersectionObserver((entries) => {
      if (first(entries)?.isIntersecting) loadNext();
    });

    const el = ref.current;
    observer.observe(el);

    return () => {
      if (el != null) {
        observer.unobserve(el);
      }
    };
  }, [hasNext, isLoadingNext, loadNext]);

  if (!hasNext) return null;

  return (
    <Button
      ref={ref}
      loading={isLoadingNext}
      onClick={() => loadNext()}
      size="medium"
      variant="text"
    />
  );
};

export const InfiniteScrollTableRow = ({
  cellCount,
  ...props
}: InfiniteScrollProps & {
  cellCount: number;
}) =>
  props.hasNext ? (
    <TableRow>
      <TableCell colSpan={cellCount}>
        <InfiniteScroll {...props} />
      </TableCell>
    </TableRow>
  ) : null;

export const InfiniteScrollMenuItem = (props: InfiniteScrollProps) =>
  props.hasNext ? (
    <MenuItem>
      <InfiniteScroll {...props} />
    </MenuItem>
  ) : null;
