import React from "react";
import { useTranslation } from "react-i18next";
import { useFeatureFlagEnabled } from "../../providers/CurrentProvider";
import { DeviceMode } from "../../screens/Devices/__generated__/DevicesTab_ScreenDeviceTableRow.graphql";
import { Column } from "../Flex";
import { borderRadius, colors, spacing } from "../../helpers/theme";
import { HelperText } from "../Typography";
import { Spacer } from "../Spacer";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export const DeviceModePicker = (props: {
  value: DeviceMode;
  onChange: (mode: string) => void;
  error: boolean;
  deviceCount?: number;
}) => {
  const modes = [
    "config",
    useFeatureFlagEnabled("playlists") ? "playlist" : null,
  ] as const;
  const { t } = useTranslation("DeviceModePicker");

  return (
    <Column
      padding="small"
      style={{
        border: `1px solid ${colors.gray4}`,
        borderRadius: borderRadius.regular,
      }}
    >
      <HelperText style={{ fontWeight: "bold" }}>{t("Device mode")}</HelperText>
      <Spacer size="tiny" />
      <HelperText>
        {t("Should this device display a configuration or a playlist?", {
          count: props.deviceCount ?? 1,
        })}
      </HelperText>
      <Spacer size="smaller" />
      <RadioGroup style={{ gap: spacing.small }}>
        {modes.map((mode) =>
          mode == null ? null : (
            <FormControlLabel
              key={mode}
              value={mode}
              control={
                <Radio
                  onChange={() => props.onChange(mode)}
                  checked={props.value == mode}
                />
              }
              label={t(mode)}
            />
          ),
        )}
      </RadioGroup>
    </Column>
  );
};
