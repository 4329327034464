import React, {
  Suspense,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Column } from "../components/Flex";
import { ScreenHeader } from "../components/ScreenHeader";
import dayjs from "dayjs";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import { Drawer } from "../components/Drawer";
import * as yup from "yup";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ConfigurationOption,
  ConfigurationPicker,
  configurationPickerValidator,
} from "../components/Pickers/ConfigurationPicker";
import {
  Playlist,
  PlaylistPicker,
  playlistPickerValidator,
} from "../components/Pickers/PlaylistPicker";
import { DateTimePicker } from "../components/Pickers/DateTimePicker";
import { useDialog } from "../providers/DialogProvider";
import { DateRange, ID, ISO8601DateTime, TimeZone } from "../helpers/types";
import { useTranslation } from "react-i18next";
import { Spacer } from "../components/Spacer";
import { useSnacks } from "../providers/SnacksProvider";
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from "react-relay";
import { DeviceScheduleScreen_CreateMutation } from "./__generated__/DeviceScheduleScreen_CreateMutation.graphql";
import { errorReporting } from "../helpers/errorReporting";
import { DeviceScheduleScreen_DeviceQuery } from "./__generated__/DeviceScheduleScreen_DeviceQuery.graphql";
import { useNavigate, useParams } from "react-router-dom";
import { DeviceScheduleScreen_EventsQuery } from "./__generated__/DeviceScheduleScreen_EventsQuery.graphql";
import { TimeZonePicker } from "../components/Pickers/TimeZonePicker";
import { userDefaultTimeZone } from "../helpers/localise";
import { View, Views } from "react-big-calendar";
import { LoadingScreen } from "../components/LoadingScreen";
import { DeviceScheduleScreen_EditItemQuery } from "./__generated__/DeviceScheduleScreen_EditItemQuery.graphql";
import { DeviceScheduleScreen_UpdateMutation } from "./__generated__/DeviceScheduleScreen_UpdateMutation.graphql";
import { useFetchKey, useIncrementFetchKey } from "../providers/RelayProvider";
import { DeleteAlertRow } from "../components/DeleteAlertRow";
import { Button } from "../components/Button";
import {
  AddRounded,
  ConnectedTvRounded,
  DeleteForeverRounded,
} from "@mui/icons-material";
import { DeviceScheduleScreen_DeleteMutation } from "./__generated__/DeviceScheduleScreen_DeleteMutation.graphql";
import { DeviceScheduleScreen_DeviceFragment$key } from "./__generated__/DeviceScheduleScreen_DeviceFragment.graphql";
import { DeviceScheduleScreen_InitMutation } from "./__generated__/DeviceScheduleScreen_InitMutation.graphql";
import { DeviceModePicker } from "../components/Pickers/DeviceModePicker";
import { useFeatureFlagEnabled } from "../providers/CurrentProvider";
import {
  ScheduleConflictChecker,
  ScheduleConflictStatus,
} from "../components/ScheduleConflictChecker";
import { inTz, inUtc } from "../helpers/datetime";
import {
  Calendar,
  CalendarContext,
  CalendarEvent,
} from "../components/Calendar";

type ScheduleScreenState =
  | {
      tag: "idle";
    }
  | {
      tag: "creating";
      startsAt: ISO8601DateTime;
      endsAt: ISO8601DateTime;
    }
  | {
      tag: "editing";
      id: ID;
      startsAt: ISO8601DateTime;
      endsAt: ISO8601DateTime;
    };

type ScheduleItemFormValues = {
  startsAt: ISO8601DateTime;
  endsAt: ISO8601DateTime;
  timezone: TimeZone;
  mode: "config" | "playlist";
  screenConfig: ConfigurationOption | null;
  playlist: Playlist | null;
};

type ScheduleItemFormRef = {
  handleSubmit: UseFormReturn<ScheduleItemFormValues>["handleSubmit"];
  isDirty: boolean;
  conflictStatus: ScheduleConflictStatus;
};

export const DeviceScheduleScreen = () => {
  if (!useFeatureFlagEnabled("deviceSchedules")) {
    throw new Error("Device schedules not enabled");
  }

  const { deviceId } = useParams();
  const { node: device } = useLazyLoadQuery<DeviceScheduleScreen_DeviceQuery>(
    graphql`
      query DeviceScheduleScreen_DeviceQuery($deviceId: ID!) {
        node(id: $deviceId) {
          __typename
          ... on ScreenDevice {
            id
            deviceSchedule {
              id
            }
            ...DeviceScheduleScreen_DeviceFragment
          }
        }
      }
    `,
    {
      deviceId: deviceId as ID,
    },
  );

  if (device?.__typename !== "ScreenDevice") {
    throw new Error("Device not found");
  }

  if (device.deviceSchedule == null) {
    return <InitDeviceSchedule screenDeviceId={device.id} />;
  }

  return <DeviceScheduleScreenInner device={device} />;
};

const InitDeviceSchedule = ({ screenDeviceId }: { screenDeviceId: ID }) => {
  const { t } = useTranslation("DeviceScheduleScreen");
  const [initSchedule] = useMutation<DeviceScheduleScreen_InitMutation>(graphql`
    mutation DeviceScheduleScreen_InitMutation($screenDeviceId: ID!) {
      createDeviceSchedule(screenDeviceId: $screenDeviceId) {
        screenDevice {
          id
          deviceSchedule {
            id
          }
          ...DeviceScheduleScreen_DeviceFragment
        }
      }
    }
  `);

  useEffect(() => {
    initSchedule({ variables: { screenDeviceId } });
  }, [screenDeviceId, initSchedule]);

  return <LoadingScreen message={t("Loading schedule")} />;
};

const DeviceScheduleScreenInner = (props: {
  device: DeviceScheduleScreen_DeviceFragment$key;
}) => {
  const device = useFragment(
    graphql`
      fragment DeviceScheduleScreen_DeviceFragment on ScreenDevice {
        id
        deviceName
        timeZone
        deviceSchedule {
          id
        }
      }
    `,
    props.device,
  );

  if (device.deviceSchedule == null) {
    throw new Error("Device schedule not found");
  }

  const { t } = useTranslation("DeviceScheduleScreen");
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const timezone = device.timeZone ?? userDefaultTimeZone;

  const searchParams = new URL(document.location.href).searchParams;
  const creating = searchParams.get("creating");
  const editing = searchParams.get("editing");
  const startsAt = searchParams.get("startsAt") as ISO8601DateTime;
  const endsAt = searchParams.get("endsAt") as ISO8601DateTime;

  const state: ScheduleScreenState =
    creating != null
      ? { tag: "creating", startsAt, endsAt }
      : editing != null
        ? { tag: "editing", id: editing, startsAt, endsAt }
        : { tag: "idle" };

  const [dateRange, setDateRange] = useState<DateRange>();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [currentView, setCurrentView] = useState<View>(
    screenSize === "sm" ? Views.DAY : Views.WEEK,
  );

  return (
    <CalendarContext.Provider
      value={{
        timezone,
        currentView,
        dateRange,
        setDateRange,
      }}
    >
      <ScreenHeader
        title={t("Device schedule")}
        breadcrumbs={[
          {
            icon: ConnectedTvRounded,
            title: t("Devices"),
            onClick: () => navigate("/devices"),
          },
          {
            title: device.deviceName,
          },
        ]}
        actions={[
          {
            startIcon: AddRounded,
            onClick: () => {
              const startsAt = inTz(new Date(), timezone)
                .startOf("hour")
                .add(1, "hour")
                .toISOString();

              const endsAt = inTz(new Date(), timezone)
                .startOf("hour")
                .add(2, "hours")
                .toISOString();

              navigate(`?creating&startsAt=${startsAt}&endsAt=${endsAt}`);
            },
            children: t("Add content"),
          },
        ]}
      />

      <Suspense>
        {dateRange != null && (
          <ScheduleItemFetcher
            deviceScheduleId={device.deviceSchedule.id}
            onItemsFetched={setEvents}
          />
        )}
      </Suspense>

      <Calendar
        view={currentView}
        onView={setCurrentView}
        events={events}
        onCreateEvent={({ start, end }) => {
          const startsAt = (
            currentView === "month"
              ? dayjs(start).startOf("day").add(8, "hours")
              : start
          ).toISOString();

          const endsAt = (
            currentView === "month"
              ? dayjs(end).startOf("day").add(12, "hours")
              : end
          ).toISOString();

          navigate(`?creating&startsAt=${startsAt}&endsAt=${endsAt}`);
        }}
        onEditEvent={(event) => {
          navigate(
            `?editing=${event.id}&startsAt=${event.start.toISOString()}&endsAt=${event.end.toISOString()}`,
          );
        }}
        onMoveEvent={({ event, start, end }) => {
          navigate(
            `?editing=${event.id}&startsAt=${dayjs(start).toISOString()}&endsAt=${dayjs(end).toISOString()}`,
          );
        }}
      />

      <CreateScheduleItemDrawer
        deviceScheduleId={device.deviceSchedule.id}
        defaultValues={
          state.tag === "creating"
            ? {
                startsAt: state.startsAt,
                endsAt: state.endsAt,
                timezone,
              }
            : undefined
        }
        onClose={() => navigate("#")}
      />

      <EditScheduleItemDrawer
        deviceScheduleId={device.deviceSchedule.id}
        defaultValues={
          state.tag === "editing"
            ? {
                id: state.id,
                startsAt: state.startsAt,
                endsAt: state.endsAt,
                timezone,
              }
            : undefined
        }
        onClose={() => navigate("#")}
      />
    </CalendarContext.Provider>
  );
};

const ScheduleItemFetcher = ({
  deviceScheduleId,
  onItemsFetched,
}: {
  deviceScheduleId: ID;
  onItemsFetched: (items: CalendarEvent[]) => void;
}) => {
  const { dateRange, timezone } = useContext(CalendarContext);
  if (dateRange == null) {
    throw new Error("ScheduleItemFetcher: DateRange not ready");
  }

  const from = dayjs(dateRange.from).utc().startOf("day");
  const to = dayjs(dateRange.to).utc().endOf("day");

  const { node: deviceSchedule } =
    useLazyLoadQuery<DeviceScheduleScreen_EventsQuery>(
      graphql`
        query DeviceScheduleScreen_EventsQuery(
          $deviceScheduleId: ID!
          $from: ISO8601DateTime!
          $to: ISO8601DateTime!
          $skip: Boolean!
        ) {
          node(id: $deviceScheduleId) @skip(if: $skip) {
            __typename
            ... on DeviceSchedule {
              id
              deviceScheduleItems(from: $from, to: $to) {
                nodes {
                  id
                  startsAt
                  endsAt
                  mode
                  playlist {
                    id
                    name
                  }
                  screenConfig {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      {
        deviceScheduleId,
        from: inTz(from, timezone).toISOString(),
        to: inTz(to, timezone).toISOString(),
        skip: dateRange == null,
      },
      {
        fetchKey: useFetchKey("schedules"),
      },
    );

  if (
    deviceSchedule != null &&
    deviceSchedule?.__typename !== "DeviceSchedule"
  ) {
    throw new Error("Device schedule not found");
  }

  useEffect(() => {
    onItemsFetched(
      (deviceSchedule?.deviceScheduleItems?.nodes ?? []).map((item) => ({
        id: item.id,
        start: inTz(item.startsAt, timezone).toDate(),
        end: inTz(item.endsAt, timezone).toDate(),
        mode: item.mode === "playlist" ? "playlist" : "config",
        title: item.playlist?.name ?? item.screenConfig?.name ?? "Unknown",
      })),
    );
  }, [deviceSchedule, onItemsFetched, timezone]);

  return null;
};

const CreateScheduleItemDrawer = (props: {
  deviceScheduleId: ID;
  defaultValues?: Partial<{
    startsAt: ISO8601DateTime;
    endsAt: ISO8601DateTime;
    timezone: TimeZone;
  }>;
  onClose: () => void;
}) => {
  const { t } = useTranslation("DeviceScheduleScreen");
  const dialog = useDialog();
  const snacks = useSnacks();
  const formRef = useRef<ScheduleItemFormRef>(null);
  const incrementFetchKey = useIncrementFetchKey();

  const [createScheduleItem, createInFlight] =
    useMutation<DeviceScheduleScreen_CreateMutation>(graphql`
      mutation DeviceScheduleScreen_CreateMutation(
        $deviceScheduleId: ID!
        $startsAt: ISO8601DateTime!
        $endsAt: ISO8601DateTime!
        $mode: DeviceScheduleItemMode!
        $screenConfigId: ID
        $playlistId: ID
      ) {
        createDeviceScheduleItem(
          deviceScheduleId: $deviceScheduleId
          startsAt: $startsAt
          endsAt: $endsAt
          mode: $mode
          screenConfigId: $screenConfigId
          playlistId: $playlistId
        ) {
          deviceScheduleItem {
            id
          }
        }
      }
    `);

  const defaultValues: ScheduleItemFormValues = useMemo(
    () => ({
      startsAt:
        props.defaultValues?.startsAt ??
        dayjs().startOf("hour").add(1, "hour").toISOString(),
      endsAt:
        props.defaultValues?.endsAt ??
        dayjs().startOf("hour").add(2, "hours").toISOString(),
      timezone: props.defaultValues?.timezone ?? userDefaultTimeZone,
      mode: "config",
      screenConfig: null,
      playlist: null,
    }),
    [props.defaultValues],
  );

  const handleClose = () => {
    dialog.beforeClose({
      isDirty: formRef.current?.isDirty ?? false,
      onClose: props.onClose,
    });
  };

  const handleCreate = (values: ScheduleItemFormValues) => {
    createScheduleItem({
      variables: {
        deviceScheduleId: props.deviceScheduleId,
        startsAt: inUtc(values.startsAt, values.timezone).toISOString(),
        endsAt: inUtc(values.endsAt, values.timezone).toISOString(),
        mode: values.mode,
        playlistId: values.playlist?.id,
        screenConfigId: values.screenConfig?.id,
      },
      onCompleted: () => {
        snacks.success(t("Schedule item created"));
        props.onClose();
      },
      onError: (err) => {
        errorReporting.sendError(err);
        snacks.error(t("Something went wrong"));
      },
      updater: (store) => {
        store.get(props.deviceScheduleId)?.invalidateRecord();
        incrementFetchKey("schedules");
      },
    });
  };

  const handleSubmit = () => {
    if (formRef.current == null) {
      throw new Error("Form not ready");
    }

    if (formRef.current.conflictStatus.status === "loading") {
      snacks.info("Please wait for conflict checker to complete");
      return;
    }

    formRef.current?.handleSubmit(
      (values) => {
        if (formRef.current!.conflictStatus.conflicts.length > 0) {
          dialog.confirm({
            title: t("Conflicts found"),
            body: t(
              `Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?`,
              { count: formRef.current!.conflictStatus.conflicts.length },
            ),
            onConfirm: () => handleCreate(values),
          });
        } else {
          handleCreate(values);
        }
      },
      () => {
        snacks.error(t("Please correct any errors and try again"));
      },
    )();
  };

  return (
    <Drawer
      open={props.defaultValues != null}
      onClose={handleClose}
      actions={[
        {
          children: t("Create schedule item"),
          onClick: handleSubmit,
          loading: createInFlight,
        },
      ]}
    >
      <ScheduleItemForm
        ref={formRef}
        deviceScheduleId={props.deviceScheduleId}
        defaultValues={defaultValues}
      />
    </Drawer>
  );
};

const EditScheduleItemDrawer = ({
  deviceScheduleId,
  defaultValues,
  onClose,
}: {
  deviceScheduleId: ID;
  defaultValues?: { id: ID } & Partial<ScheduleItemFormValues>;
  onClose: () => void;
}) => {
  const { t } = useTranslation("DeviceScheduleScreen");
  const dialog = useDialog();
  const snacks = useSnacks();
  const formRef = useRef<ScheduleItemFormRef>(null);
  const incrementFetchKey = useIncrementFetchKey();

  const [updateScheduleItem, updateInFlight] =
    useMutation<DeviceScheduleScreen_UpdateMutation>(graphql`
      mutation DeviceScheduleScreen_UpdateMutation(
        $deviceScheduleItemId: ID!
        $startsAt: ISO8601DateTime!
        $endsAt: ISO8601DateTime!
        $mode: DeviceScheduleItemMode!
        $screenConfigId: ID
        $playlistId: ID
      ) {
        updateDeviceScheduleItem(
          deviceScheduleItemId: $deviceScheduleItemId
          startsAt: $startsAt
          endsAt: $endsAt
          mode: $mode
          screenConfigId: $screenConfigId
          playlistId: $playlistId
        ) {
          deviceScheduleItem {
            id
          }
        }
      }
    `);

  const handleClose = () => {
    dialog.beforeClose({
      isDirty: formRef.current?.isDirty ?? false,
      onClose: onClose,
    });
  };

  const handleUpdate = (values: ScheduleItemFormValues) => {
    if (defaultValues?.id == null) {
      throw new Error("Tried to update schedule item without id");
    }

    updateScheduleItem({
      variables: {
        deviceScheduleItemId: defaultValues.id,
        startsAt: inUtc(values.startsAt, values.timezone).toISOString(),
        endsAt: inUtc(values.endsAt, values.timezone).toISOString(),
        mode: values.mode,
        playlistId: values.playlist?.id,
        screenConfigId: values.screenConfig?.id,
      },
      onCompleted: () => {
        snacks.success(t("Schedule item updated"));
        onClose();
      },
      onError: (err) => {
        errorReporting.sendError(err);
        snacks.error(t("Something went wrong"));
      },
      updater: (store) => {
        store.get(deviceScheduleId)?.invalidateRecord();
        incrementFetchKey("schedules");
      },
    });
  };

  const handleSubmit = () => {
    if (formRef.current == null) {
      throw new Error("Form not ready");
    }

    if (formRef.current.conflictStatus.status === "loading") {
      snacks.info("Please wait for conflict checker to complete");
      return;
    }

    formRef.current?.handleSubmit(
      (values) => {
        if (formRef.current!.conflictStatus.conflicts.length > 0) {
          dialog.confirm({
            title: t("Conflicts found"),
            body: t(
              `Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?`,
              { count: formRef.current!.conflictStatus.conflicts.length },
            ),
            onConfirm: () => handleUpdate(values),
          });
        } else {
          handleUpdate(values);
        }
      },
      () => {
        snacks.error(t("Please correct any errors and try again"));
      },
    )();
  };

  return (
    <Drawer
      open={defaultValues?.id != null}
      onClose={handleClose}
      actions={[
        {
          children: t("Update schedule item"),
          onClick: handleSubmit,
          loading: updateInFlight,
        },
      ]}
    >
      <Suspense fallback={<LoadingScreen message="Loading schedule item" />}>
        {defaultValues?.id != null && (
          <EditScheduleItemDrawerInner
            ref={formRef}
            deviceScheduleId={deviceScheduleId}
            deviceScheduleItemId={defaultValues.id}
            defaultValues={defaultValues}
            onClose={onClose}
          />
        )}
      </Suspense>
    </Drawer>
  );
};

const EditScheduleItemDrawerInner = forwardRef<
  ScheduleItemFormRef,
  {
    deviceScheduleId: ID;
    deviceScheduleItemId: ID;
    onClose: () => void;
    defaultValues: Partial<{
      startsAt: ISO8601DateTime;
      endsAt: ISO8601DateTime;
      timezone: TimeZone;
    }>;
  }
>(function EditScheduleItemDrawerInner(
  { deviceScheduleId, deviceScheduleItemId, defaultValues, onClose },
  ref,
) {
  const { t } = useTranslation("DeviceScheduleScreen");
  const dialog = useDialog();
  const snacks = useSnacks();
  const incrementFetchKey = useIncrementFetchKey();
  const { node } = useLazyLoadQuery<DeviceScheduleScreen_EditItemQuery>(
    graphql`
      query DeviceScheduleScreen_EditItemQuery($deviceScheduleItemId: ID!) {
        node(id: $deviceScheduleItemId) {
          __typename
          ... on DeviceScheduleItem {
            id
            startsAt
            endsAt
            mode
            screenConfig {
              id
              name
              configType
            }
            playlist {
              id
              name
            }
          }
        }
      }
    `,
    {
      deviceScheduleItemId,
    },
  );

  if (node?.__typename !== "DeviceScheduleItem") {
    throw new Error("DeviceScheduleItem not found");
  }

  const [deleteScheduleItem] = useMutation<DeviceScheduleScreen_DeleteMutation>(
    graphql`
      mutation DeviceScheduleScreen_DeleteMutation($deviceScheduleItemId: ID!) {
        deleteDeviceScheduleItem(deviceScheduleItemId: $deviceScheduleItemId) {
          deviceScheduleItem {
            id
          }
        }
      }
    `,
  );

  const handleDelete = () => {
    dialog.confirm({
      title: t("Delete schedule item"),
      body: t("Are you sure you want to delete this schedule item?"),
      confirmColor: "destructive",
      onConfirm: () =>
        new Promise((resolve, reject) => {
          deleteScheduleItem({
            variables: { deviceScheduleItemId },
            onCompleted: () => {
              resolve();
              onClose();
              snacks.success(t("Schedule item deleted"));
            },
            onError: (err) => {
              reject();
              errorReporting.sendError(err);
              snacks.success(t("Something went wrong"));
            },
            updater: (store) => {
              store.get(deviceScheduleId)?.invalidateRecord();
              incrementFetchKey("schedules");
            },
          });
        }),
    });
  };

  return (
    <>
      <ScheduleItemForm
        ref={ref}
        deviceScheduleId={deviceScheduleId}
        deviceScheduleItemId={deviceScheduleItemId}
        defaultValues={{
          startsAt: defaultValues.startsAt ?? node.startsAt,
          endsAt: defaultValues.endsAt ?? node.endsAt,
          mode: node.mode === "playlist" ? "playlist" : "config",
          timezone: defaultValues.timezone ?? userDefaultTimeZone,
          screenConfig: node.screenConfig ?? null,
          playlist: node.playlist ?? null,
        }}
      />
      <Spacer size="large" style={{ flexGrow: 1 }} />
      <DeleteAlertRow>
        <Button
          color="destructive"
          startIcon={DeleteForeverRounded}
          onClick={handleDelete}
        >
          {t("Delete schedule item")}
        </Button>
      </DeleteAlertRow>
    </>
  );
});

const ScheduleItemForm = forwardRef<
  ScheduleItemFormRef,
  {
    deviceScheduleId: ID;
    deviceScheduleItemId?: ID;
    defaultValues: ScheduleItemFormValues;
  }
>(function ScheduleItemForm(
  { deviceScheduleId, deviceScheduleItemId, defaultValues },
  ref,
) {
  const { t } = useTranslation("DeviceScheduleScreen");
  const [conflictStatus, setConflictStatus] = useState<ScheduleConflictStatus>({
    status: "ready",
    conflicts: [],
  });

  const form = useForm<ScheduleItemFormValues>({
    defaultValues,
    resolver: yupResolver(
      yup.object({
        startsAt: yup.string().required(),
        endsAt: yup
          .string()
          .required()
          .test(
            "is-greater",
            t("End time should be after the start time"),
            function (value) {
              return dayjs(value).isAfter(this.parent.startsAt);
            },
          )
          .test(
            "in-future",
            t("Schedule item cannot be in the past"),
            function (value) {
              if (value == null) return false;
              return inUtc(value, this.parent.timezone).isAfter(new Date());
            },
          ),
        timezone: yup.string().required(),
        mode: yup.mixed().required(),
        screenConfig: configurationPickerValidator
          .optional()
          .nullable()
          .when("mode", {
            is: "config",
            then: () => configurationPickerValidator.required(),
          }),
        playlist: playlistPickerValidator
          .optional()
          .nullable()
          .when("mode", {
            is: "playlist",
            then: () => playlistPickerValidator.required(),
          }),
      }),
    ),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  useImperativeHandle(ref, () => ({
    handleSubmit: form.handleSubmit,
    isDirty: form.formState.isDirty,
    conflictStatus,
  }));

  return (
    <Column gap="medium" padding="medium">
      <Controller
        control={form.control}
        name="startsAt"
        render={({ field, fieldState }) => (
          <DateTimePicker
            label={t("Starts at")}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.invalid}
          />
        )}
      />

      <Controller
        control={form.control}
        name="endsAt"
        render={({ field, fieldState }) => (
          <DateTimePicker
            label={t("Ends at")}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={form.control}
        name="timezone"
        render={({ field }) => (
          <TimeZonePicker value={field.value} onChange={field.onChange} />
        )}
      />

      <ScheduleConflictChecker
        deviceScheduleId={deviceScheduleId}
        excludedDeviceScheduleItemIds={
          deviceScheduleItemId == null ? [] : [deviceScheduleItemId]
        }
        startsAt={form.watch("startsAt")}
        endsAt={form.watch("endsAt")}
        timezone={form.watch("timezone")}
        value={conflictStatus}
        onChange={setConflictStatus}
      />

      <Controller
        control={form.control}
        name="mode"
        render={({ field, fieldState }) => (
          <DeviceModePicker
            value={field.value}
            onChange={field.onChange}
            error={fieldState.invalid}
          />
        )}
      />

      {form.watch("mode") === "config" && (
        <Controller
          control={form.control}
          name="screenConfig"
          render={({ field, fieldState }) => (
            <ConfigurationPicker
              value={field.value}
              onChange={field.onChange}
              error={fieldState.invalid}
            />
          )}
        />
      )}

      {form.watch("mode") === "playlist" && (
        <Controller
          control={form.control}
          name="playlist"
          render={({ field, fieldState }) => (
            <PlaylistPicker
              value={field.value}
              onChange={field.onChange}
              error={fieldState.invalid}
            />
          )}
        />
      )}
    </Column>
  );
});
