import React from "react";
import { spacing } from "../helpers/theme";
import { Alert, AlertProps } from "@mui/material";

export const DeleteAlertRow = ({
  icon,
  severity,
  style,
  ...props
}: AlertProps) => (
  <Alert
    {...props}
    icon={icon ?? false}
    severity={severity ?? "error"}
    style={{ justifyContent: "flex-end", padding: spacing.medium, ...style }}
  />
);
