import React, { ReactNode } from "react";
import {
  // eslint-disable-next-line no-restricted-imports
  DrawerProps as MuiDrawerProps,
  IconButton,
  // eslint-disable-next-line no-restricted-imports
  Drawer as MaterialDrawer,
} from "@mui/material";
import { Column, Row } from "./Flex";
import { Close } from "@mui/icons-material";
import { colors } from "../helpers/theme";
import { Button, ButtonProps } from "./Button";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import { Tooltip } from "./Tooltip";
import { useTranslation } from "react-i18next";

export type DrawerProps = Omit<MuiDrawerProps, "onClose"> & {
  onClose: () => void;
  actions?: ButtonProps[];
  headerBackgroundColor?: string;
  HeaderActionsContent?: ReactNode;
  FooterActionsContent?: ReactNode;
  size?: "small" | "medium" | "large";
};

export const Drawer = ({
  anchor,
  sx,
  children,
  actions,
  headerBackgroundColor,
  HeaderActionsContent,
  FooterActionsContent,
  size,
  ...props
}: DrawerProps) => {
  const screenSize = useScreenSize();
  const { t } = useTranslation("Drawer");
  return (
    <MaterialDrawer
      anchor={(anchor ?? screenSize === "sm") ? "bottom" : "right"}
      sx={{
        "& .MuiDrawer-paper": {
          width:
            size === "large"
              ? "100vw"
              : screenSize === "sm"
                ? "100%"
                : size === "small"
                  ? 640
                  : 700,
          maxWidth: size === "large" ? 1000 : "100%",
          height: "100%",
        },
        ...sx,
      }}
      {...props}
    >
      <Column grow={1} style={{ height: "100%" }}>
        <Column
          style={{
            backgroundColor: headerBackgroundColor ?? colors.white,
          }}
        >
          <Row padding="small" gap="small" alignItems="centers">
            <Tooltip title={t("Close")} placement="left">
              <Column justifyContent="center">
                <IconButton onClick={props.onClose}>
                  <Close />
                </IconButton>
              </Column>
            </Tooltip>
            <Row fullWidth alignItems="center" justifyContent="flex-end">
              {HeaderActionsContent}
            </Row>
          </Row>
        </Column>
        <Column grow={1} style={{ overflowY: "auto" }}>
          {children}
        </Column>
        {((actions ?? []).length > 0 || FooterActionsContent != null) && (
          <Row
            fullWidth
            alignItems="center"
            justifyContent="flex-end"
            padding="small"
            gap="small"
            style={{
              borderTop: `1px solid ${colors.gray4}`,
              background: colors.gray1,
            }}
          >
            {(actions ?? []).map((action, i) => (
              <Button key={i} {...action} />
            ))}
            {FooterActionsContent}
          </Row>
        )}
      </Column>
    </MaterialDrawer>
  );
};
