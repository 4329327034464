import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import React, { createContext, useContext, useEffect } from "react";
import dayjs from "dayjs";
import {
  Calendar as BigCalendar,
  EventProps,
  HeaderProps,
  ToolbarProps,
  View,
  dayjsLocalizer,
} from "react-big-calendar";
import withDragAndDrop, {
  EventInteractionArgs,
} from "react-big-calendar/lib/addons/dragAndDrop";
import { DateRange, ID, TimeZone } from "../helpers/types";
import {
  borderRadius,
  colors,
  fontSize,
  iconSize,
  spacing,
  toRgba,
} from "../helpers/theme";
import { makeStyles } from "@mui/styles";
import { Column, Row } from "./Flex";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import { formatDateTime, useDateFormatter } from "../hooks/useDateFormatter";
import { inTz } from "../helpers/datetime";
import { first, last } from "lodash";
import { useTranslation } from "react-i18next";
import { Tooltip } from "./Tooltip";
import { Button } from "./Button";
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  PublicRounded,
} from "@mui/icons-material";
import { BoldText, HelperText, Label, SubHeading } from "./Typography";
import { allTimezones } from "react-timezone-select";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LocaleName } from "../helpers/localise";

const useStyles = makeStyles({
  calendar: {
    overflow: "hidden",
    "& .rbc-allday-cell": {
      display: "none",
    },
    "& .rbc-today": {
      backgroundColor: toRgba(colors.gray1, 0.5),
    },
    "& .rbc-header": {
      borderBottom: 0,
    },
    "& .rbc-time-content": {
      borderTop: 0,
    },
    "& .rbc-current-time-indicator": {
      backgroundColor: colors.red3,
    },
    "& .rbc-time-view": {
      borderRadius: borderRadius.regular,
      overflow: "hidden",
      borderColor: colors.gray4,
    },
    "& .rbc-time-gutter": {
      color: colors.gray6,
      fontSize: fontSize.detail,
      "& .rbc-timeslot-group": {
        borderBottom: 0,
        "&:first-child": { opacity: 0 },
      },
      "& .rbc-time-slot": {
        marginTop: -9,
      },
    },
    "& .rbc-timeslot-group": {
      borderBottomColor: colors.gray2,
    },
    "& .rbc-today .rbc-timeslot-group": {
      borderBottomColor: colors.gray3,
    },
    "& .rbc-day-slot .rbc-time-slot": {
      borderTop: 0,
    },
    "& .rbc-events-container > .rbc-event, & .rbc-month-row .rbc-event": {
      border: 0,
      backgroundColor: "transparent",
      padding: 0,
      outline: "none",
      "& .rbc-event-label": {
        display: "none",
      },
    },
    "& .rbc-month-view .rbc-addons-dnd-resize-ew-anchor": {
      display: "none",
    },
    "& .rbc-addons-dnd-resize-ns-icon": {
      color: colors.black,
    },
    "& .rbc-agenda-view": {
      borderRadius: borderRadius.medium,
      overflow: "hidden",
      border: `1px solid ${colors.gray3}`,
      flex: "none",
      "& table.rbc-agenda-table": {
        borderRadius: borderRadius.medium,
        overflow: "hidden",
        "& thead > tr > th": {
          height: 44,
          fontWeight: 600,
          color: colors.gray6,
          backgroundColor: colors.gray1,
          padding: `${spacing.smaller}px ${spacing.medium}px`,
          fontSize: fontSize.small,
        },
        "& tbody > tr > td": {
          borderRight: `1px solid ${colors.gray3}`,
          padding: `${spacing.smaller}px ${spacing.medium}px`,
          fontSize: fontSize.body,
          "&.rbc-agenda-date-cell": {
            fontSize: fontSize.small,
            fontWeight: 500,
          },
          "&.rbc-agenda-time-cell": {
            fontSize: fontSize.small,
          },
          "&.rbc-agenda-event-cell:hover": {
            cursor: "pointer",
            backgroundColor: colors.gray1,
          },
        },
        "& tbody > tr > td + td": {
          borderLeft: 0,
        },
      },
      "& .rbc-agenda-content": {
        borderTop: `1px solid ${colors.gray4}`,
      },
    },
  },
  calendarEventWrapper: {
    border: `1px solid ${colors.gray3}`,
    backgroundColor: colors.fadedBlue,
    borderRadius: borderRadius.regular,
    overflow: "hidden",
    marginLeft: 2,
    height: "100%",
  },
  calendarEventBar: {
    width: 4,
    minWidth: 4,
    backgroundColor: colors.blue2,
    borderRadius: borderRadius.small,
    margin: `${spacing.smallest}px 0 ${spacing.small}px ${spacing.smallest}px`,
  },
  eventTitle: {
    color: colors.black,
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  eventTime: {
    whiteSpace: "wrap",
  },
});

export type CalendarEvent = {
  id: ID;
  start: Date;
  end: Date;
  mode: "playlist" | "config";
  title: string;
};

const localizer = dayjsLocalizer(dayjs);
const DnDCalendar = withDragAndDrop(BigCalendar<CalendarEvent>);

type CalendarContextType = {
  timezone: TimeZone;
  currentView: View;
  dateRange?: DateRange;
  setDateRange: (dateRange: DateRange) => void;
};

export const CalendarContext = createContext<CalendarContextType>(null!);

export const Calendar = (props: {
  view: View;
  onView: (view: View) => void;
  events: CalendarEvent[];
  onCreateEvent: (args: { start: Date; end: Date }) => void;
  onMoveEvent: (event: EventInteractionArgs<CalendarEvent>) => void;
  onEditEvent: (event: CalendarEvent) => void;
}) => {
  const screenSize = useScreenSize();
  const classes = useStyles();
  const dateFormatter = useDateFormatter();
  const { timezone, setDateRange } = useContext(CalendarContext);

  return (
    <Column
      grow={1}
      padding={screenSize === "sm" ? "none" : "medium"}
      className={classes.calendar}
    >
      <DnDCalendar
        selectable
        localizer={localizer}
        view={props.view}
        onView={props.onView}
        step={5}
        timeslots={3}
        formats={{
          timeGutterFormat: (date) => dateFormatter.timeFormat(date),
        }}
        scrollToTime={inTz(new Date(), timezone)
          .subtract(30, "minutes")
          .toDate()}
        components={{
          toolbar: CalendarToolbar,
          week: {
            header: CalendarWeekHeader,
            event: CustomCalendarEvent,
          },
          day: {
            event: CustomCalendarEvent,
          },
          month: {
            event: CustomMonthCalendarEvent,
          },
        }}
        events={props.events}
        showMultiDayTimes
        getNow={() => inTz(new Date(), timezone).toDate()}
        onRangeChange={(range) => {
          if ("start" in range && "end" in range) {
            setDateRange({ from: range.start, to: range.end });
          } else {
            setDateRange({ from: first(range)!, to: last(range)! });
          }
        }}
        onSelectSlot={props.onCreateEvent}
        onSelectEvent={props.onEditEvent}
        onEventResize={props.onMoveEvent}
        onEventDrop={props.onMoveEvent}
      />
    </Column>
  );
};

const CalendarToolbar = ({ onView, ...props }: ToolbarProps<CalendarEvent>) => {
  // triggering onView on first load forces the "onRangeChange" to trigger
  // to set the initial date range for the calendar
  useEffect(() => {
    onView(props.view);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onView]);

  const { t } = useTranslation("Calendar");
  const screenSize = useScreenSize();
  const { dateRange, currentView, timezone } = useContext(CalendarContext);
  const dateFormatter = useDateFormatter();

  // only allow day view on small screens
  useEffect(() => {
    if (currentView !== "day" && screenSize === "sm") {
      onView("day");
    }
  }, [screenSize, currentView, onView]);

  return (
    <Row
      alignItems="center"
      justifyContent="space-between"
      gap="medium"
      paddingHorizontal={screenSize === "sm" ? "small" : "none"}
      paddingVertical="medium"
      style={{
        borderTop: screenSize === "sm" ? 0 : `1px solid ${colors.gray4}`,
        overflowX: "auto",
      }}
    >
      <Row gap="smallest" alignItems="center">
        <Tooltip title={t("Previous")}>
          <Row>
            <Button
              variant="secondary"
              size="medium"
              startIcon={ChevronLeftRounded}
              onClick={() => props.onNavigate("PREV")}
            />
          </Row>
        </Tooltip>
        <Button
          variant="secondary"
          size="medium"
          onClick={() => props.onNavigate("TODAY")}
        >
          {t("Today")}
        </Button>
        <Tooltip title={t("Next")}>
          <Row>
            <Button
              variant="secondary"
              size="medium"
              startIcon={ChevronRightRounded}
              onClick={() => props.onNavigate("NEXT")}
            />
          </Row>
        </Tooltip>
      </Row>

      <Column grow={1}>
        {dateRange != null && (
          <BoldText style={{ whiteSpace: "nowrap" }}>
            {currentView === "day"
              ? dateFormatter.dayMonthYearFormat(dateRange.from)
              : currentView === "week" || currentView === "agenda"
                ? dateFormatter.range.dayMonthYear(dateRange.from, dateRange.to)
                : props.label}
          </BoldText>
        )}
        <Row alignItems="center" gap="tiny">
          <PublicRounded
            sx={{ width: iconSize.small, height: iconSize.small }}
          />
          <HelperText>{allTimezones[timezone]}</HelperText>
        </Row>
      </Column>

      {screenSize !== "sm" && (
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel>{t("View")}</InputLabel>
          <Select
            value={props.view}
            label={t("View")}
            size="small"
            onChange={(ev) => onView(ev.target.value as View)}
          >
            {(props.views as View[]).map((view) => (
              <MenuItem key={view} value={view}>
                {t(view)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Row>
  );
};

const CustomCalendarEvent = ({ event }: EventProps<CalendarEvent>) => {
  const { currentView } = useContext(CalendarContext);
  const screenSize = useScreenSize();
  const dateFormatter = useDateFormatter();
  const classes = useStyles();

  return (
    <Row className={classes.calendarEventWrapper}>
      {!(screenSize === "sm" && currentView === "week") && (
        <Column className={classes.calendarEventBar} />
      )}
      <Column paddingVertical="smaller" paddingHorizontal="smallest">
        <HelperText className={classes.eventTitle}>{event.title}</HelperText>
        <HelperText className={classes.eventTime}>
          {[
            dateFormatter.timeFormat(event.start),
            dateFormatter.timeFormat(event.end),
          ].join(" - ")}
        </HelperText>
      </Column>
    </Row>
  );
};

const CustomMonthCalendarEvent = ({ event }: EventProps<CalendarEvent>) => {
  const dateFormatter = useDateFormatter();
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={[
        dateFormatter.timeFormat(event.start),
        dateFormatter.timeFormat(event.end),
      ].join(" - ")}
    >
      <Row className={classes.calendarEventWrapper}>
        <Column paddingVertical="tiny" paddingHorizontal="smallest">
          <HelperText className={classes.eventTitle}>{event.title}</HelperText>
        </Column>
      </Row>
    </Tooltip>
  );
};

const CalendarWeekHeader = (props: HeaderProps) => {
  const { i18n } = useTranslation();
  const { timezone } = useContext(CalendarContext);
  const isToday = inTz(new Date(), timezone).isSame(props.date, "day");

  return (
    <Column
      paddingVertical="smaller"
      alignItems="center"
      justifyContent="center"
    >
      <Label style={{ fontWeight: "bold", textTransform: "uppercase" }}>
        {formatDateTime(props.date, "EEE", i18n.language as LocaleName)}
      </Label>
      <Column
        backgroundColor={isToday ? colors.red3 : undefined}
        alignItems="center"
        justifyContent="center"
        style={{
          width: iconSize.large,
          height: iconSize.large,
          borderRadius: 100,
        }}
      >
        <SubHeading style={{ color: isToday ? colors.white : undefined }}>
          {formatDateTime(props.date, "d", i18n.language as LocaleName)}
        </SubHeading>
      </Column>
    </Column>
  );
};
