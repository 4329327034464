import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";

export const InputDropdown = ({ children }: PropsWithChildren) => {
  return (
    <Paper
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 56,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 999999,
        maxHeight: "40vh",
      }}
    >
      {children}
    </Paper>
  );
};
