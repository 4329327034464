/**
 * @generated SignedSource<<435da3198191f1c70804fd188887f980>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceScheduleItemMode = "config" | "playlist" | "%future added value";
export type DeviceScheduleScreen_CreateMutation$variables = {
  deviceScheduleId: string;
  endsAt: string;
  mode: DeviceScheduleItemMode;
  playlistId?: string | null | undefined;
  screenConfigId?: string | null | undefined;
  startsAt: string;
};
export type DeviceScheduleScreen_CreateMutation$data = {
  readonly createDeviceScheduleItem: {
    readonly deviceScheduleItem: {
      readonly id: string;
    };
  };
};
export type DeviceScheduleScreen_CreateMutation = {
  response: DeviceScheduleScreen_CreateMutation$data;
  variables: DeviceScheduleScreen_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceScheduleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endsAt"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "playlistId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "screenConfigId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startsAt"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceScheduleId",
        "variableName": "deviceScheduleId"
      },
      {
        "kind": "Variable",
        "name": "endsAt",
        "variableName": "endsAt"
      },
      {
        "kind": "Variable",
        "name": "mode",
        "variableName": "mode"
      },
      {
        "kind": "Variable",
        "name": "playlistId",
        "variableName": "playlistId"
      },
      {
        "kind": "Variable",
        "name": "screenConfigId",
        "variableName": "screenConfigId"
      },
      {
        "kind": "Variable",
        "name": "startsAt",
        "variableName": "startsAt"
      }
    ],
    "concreteType": "CreateDeviceScheduleItemPayload",
    "kind": "LinkedField",
    "name": "createDeviceScheduleItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceScheduleItem",
        "kind": "LinkedField",
        "name": "deviceScheduleItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceScheduleScreen_CreateMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeviceScheduleScreen_CreateMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "162045b5b6854fd67b80184d821cf8cf",
    "id": null,
    "metadata": {},
    "name": "DeviceScheduleScreen_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation DeviceScheduleScreen_CreateMutation(\n  $deviceScheduleId: ID!\n  $startsAt: ISO8601DateTime!\n  $endsAt: ISO8601DateTime!\n  $mode: DeviceScheduleItemMode!\n  $screenConfigId: ID\n  $playlistId: ID\n) {\n  createDeviceScheduleItem(deviceScheduleId: $deviceScheduleId, startsAt: $startsAt, endsAt: $endsAt, mode: $mode, screenConfigId: $screenConfigId, playlistId: $playlistId) {\n    deviceScheduleItem {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62a5c793a4a198681bbe4c8208da94a8";

export default node;
