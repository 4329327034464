import React from "react";
import { Heading, HelperText } from "./Typography";
import { Column, Row } from "./Flex";
import { borderRadius, colors, iconSize, spacing } from "../helpers/theme";
import { Button, ButtonProps } from "./Button";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import { TabGroup, TabGroupProps } from "./TabGroup";
import { createTeleporter } from "react-teleporter";
import {
  ProductTourStep,
  useStartTour,
} from "../providers/ProductTourProvider";
import { IconButton } from "@mui/material";
import { ChevronRightRounded, HelpRounded } from "@mui/icons-material";
import { Tooltip } from "./Tooltip";
import { useFeatureFlagEnabled } from "../providers/CurrentProvider";

const useClasses = makeStyles(() => ({
  container: {
    margin: spacing.small,
    marginBottom: 0,
    marginTop: spacing.large,
    justifyContent: "space-between",
    gap: spacing.small,
  },
  containerMedium: {
    margin: spacing.medium,
    marginBottom: 0,
    marginTop: spacing.larger,
    alignItems: "center",
  },
  breadcrumbConatiner: {
    marginTop: -spacing.small,
  },
  breadcrumbItem: {
    padding: spacing.tiny,
    borderRadius: borderRadius.regular,
  },
  breadcrumbItemClickable: {
    backgroundColor: colors.gray1,
    paddingLeft: spacing.smallest,
    paddingRight: spacing.smallest,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.gray2,
    },
  },
  breadcrumbIcon: {
    width: iconSize.regular,
    height: iconSize.regular,
    color: colors.gray6,
  },
}));

export const ScreenHeaderActionsPortal = createTeleporter();

export const ScreenHeader = (props: {
  title: string;
  description?: string;
  actions?: Array<ButtonProps>;
  tabGroupProps?: TabGroupProps;
  tour?: {
    title: string;
    steps: Array<ProductTourStep>;
  };
  breadcrumbs?: {
    icon?: typeof ChevronRightRounded;
    title: string;
    onClick?: () => void;
  }[];
}) => {
  const classes = useClasses();
  const screenSize = useScreenSize();
  const startTour = useStartTour();
  const productToursEnabled = useFeatureFlagEnabled("productTours");

  return (
    <Column gap="small">
      <Row
        style={{ flexDirection: screenSize === "sm" ? "column" : "row" }}
        className={classNames(
          classes.container,
          screenSize !== "sm" ? classes.containerMedium : undefined,
        )}
      >
        <Row gap="small" grow={1}>
          <Column gap="smallest" grow={1}>
            {props.breadcrumbs != null && (
              <Row
                alignItems="center"
                gap="tiny"
                className={classes.breadcrumbConatiner}
              >
                {props.breadcrumbs.map((item, index) => (
                  <Row key={index} alignItems="center" gap="tiny">
                    {index !== 0 && (
                      <ChevronRightRounded className={classes.breadcrumbIcon} />
                    )}
                    <Row
                      gap="smallest"
                      alignItems="center"
                      onClick={item.onClick}
                      className={classNames(
                        classes.breadcrumbItem,
                        item.onClick == null
                          ? undefined
                          : classes.breadcrumbItemClickable,
                      )}
                    >
                      {item.icon != null && (
                        <item.icon className={classes.breadcrumbIcon} />
                      )}
                      <HelperText style={{ color: colors.black }}>
                        {item.title}
                      </HelperText>
                    </Row>
                  </Row>
                ))}
              </Row>
            )}
            <Row alignItems="center" gap="smallest">
              <Heading>{props.title}</Heading>
              {productToursEnabled && props.tour != null && (
                <Tooltip title={props.tour.title} arrow placement="right">
                  <IconButton
                    size="small"
                    onClick={() => startTour(props.tour!.steps)}
                  >
                    <HelpRounded
                      sx={{ width: iconSize.regular, height: iconSize.regular }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Row>
            {screenSize !== "sm" && props.description != null && (
              <HelperText>{props.description}</HelperText>
            )}
          </Column>
        </Row>
        <Row>
          <ScreenHeaderActionsPortal.Target />
          {(props.actions ?? []).map((action, i) => (
            <Button key={i} {...action} />
          ))}
        </Row>
      </Row>
      {props.tabGroupProps != null && <TabGroup {...props.tabGroupProps} />}
    </Column>
  );
};
