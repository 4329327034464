import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import timezonePlugin from "dayjs/plugin/timezone";
import { ISO8601DateTime, TimeZone } from "./types";

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

export const inTz = (
  date: Date | ISO8601DateTime | dayjs.Dayjs,
  tz: TimeZone,
) => {
  const offset = dayjs(date).tz(tz).utcOffset();
  return dayjs(date).add(offset, "minutes");
};

export const inUtc = (
  date: Date | ISO8601DateTime | dayjs.Dayjs,
  tz: TimeZone,
) => {
  const offset = dayjs(date).tz(tz).utcOffset();
  return dayjs(date).subtract(offset, "minutes");
};
