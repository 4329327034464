import i18n, { type KeyPrefix } from "i18next";
import {
  UseTranslationOptions,
  UseTranslationResponse,
  initReactI18next,
} from "react-i18next";
import { Localisation, Namespace, english } from "../localisations/en";
import { englishUS } from "../localisations/enUS";
import { spanish } from "../localisations/es";
import { french } from "../localisations/fr";
import { dutch } from "../localisations/nl";
import { italian } from "../localisations/it";
import { first } from "lodash";
import { config } from "../config";
import moment from "moment";
import { englishDevelopment } from "../localisations/enDev";
import type { Locale } from "date-fns";
import { enGB } from "date-fns/locale";
import { enUS } from "date-fns/locale";
import { es } from "date-fns/locale";
import { fr } from "date-fns/locale";
import { nl } from "date-fns/locale";
import { it } from "date-fns/locale";
import { de } from "date-fns/locale";
import { pt } from "date-fns/locale";
import { ptBR } from "date-fns/locale";
import { TimeZone } from "./types";
import { german } from "../localisations/de";
import { portuguese } from "../localisations/pt";
import { portugueseBrazilian } from "../localisations/ptBR";

export type { Localisation } from "../localisations/en";

export const userDefaultTimeZone = Intl.DateTimeFormat().resolvedOptions()
  .timeZone as TimeZone;

export const dateFnsLocales: { [localeName in LocaleName]: Locale } = {
  en: enGB,
  "en-US": enUS,
  "en-GB": enGB,
  es: es,
  "es-ES": es,
  fr: fr,
  nl: nl,
  it: it,
  de: de,
  pt: pt,
  "pt-BR": ptBR,
};

const resources = {
  en: config.isDevelopment ? englishDevelopment : english,
  "en-GB": config.isDevelopment ? englishDevelopment : english,
  "en-US": englishUS,
  es: spanish,
  "es-ES": spanish,
  fr: french,
  nl: dutch,
  it: italian,
  de: german,
  pt: portuguese,
  "pt-BR": portugueseBrazilian,
};

export type LocaleName = keyof typeof resources;

// Augment the return type of react-i18next so that i18n.language returns a `LocaleName` rather than `string`
declare module "react-i18next" {
  type NamespaceOptions<N extends Namespace> = Localisation[N];
  export function useTranslation<
    Ns extends Namespace,
    KPrefix extends KeyPrefix<Ns> = undefined,
  >(
    ns: Ns,
    options?: UseTranslationOptions<KPrefix>,
  ): Omit<UseTranslationResponse<Ns, KPrefix>, "t"> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t: (key: keyof NamespaceOptions<Ns>, args?: any) => string;
  };
}

export const supportedLocales = new Set(Object.keys(resources));

const getLanguageCode = (locale: LocaleName) => first(locale.split("-"));

export const deviceLocale: LocaleName = (() => {
  const locales =
    navigator?.languages ??
    (navigator?.language != null ? [navigator.language] : []);

  for (const locale of locales) {
    if (supportedLocales.has(locale)) {
      return locale as LocaleName;
    } else {
      const language = getLanguageCode(locale as LocaleName);
      if (language != null && supportedLocales.has(language)) {
        return language as LocaleName;
      }
    }
  }

  return "en";
})();

export const initLocalisation = (): void => {
  i18n.use(initReactI18next).init({
    compatibilityJSON: "v4",
    debug: config.isDevelopment,
    resources,
    lng: deviceLocale,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};

export function switchLocale(locale: LocaleName): Promise<void> {
  moment.locale(locale);
  return Promise.all([i18n.changeLanguage(locale)]).then(() => {});
}
