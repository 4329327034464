import { useTranslation } from "react-i18next";
import { ConfigScreen } from "../screens/__generated__/ConfigsScreen_CreateMutation.graphql";

export const useReadableScreenTemplateName = () => {
  const { t } = useTranslation("ScreenTemplateNames");
  return (template: ConfigScreen) => {
    switch (template) {
      case "l_layout":
        return t("L shape with media");
      case "media_only":
        return t("Media only");
      case "schedule_only":
        return t("Schedule only");
      case "multi_day_schedule":
        return t("Multi day schedule");
      case "multi_column_schedule":
        return t("Multi column schedule");
      case "basic":
        return t("Basic");
      default:
        throw new Error(
          `readableTemplateName: Unexpected template ${template}`,
        );
    }
  };
};
