/**
 * @generated SignedSource<<242b4b8991fb6ea10b5f28d3cb4ab6a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceScheduleItemMode = "config" | "playlist" | "%future added value";
export type DeviceScheduleScreen_UpdateMutation$variables = {
  deviceScheduleItemId: string;
  endsAt: string;
  mode: DeviceScheduleItemMode;
  playlistId?: string | null | undefined;
  screenConfigId?: string | null | undefined;
  startsAt: string;
};
export type DeviceScheduleScreen_UpdateMutation$data = {
  readonly updateDeviceScheduleItem: {
    readonly deviceScheduleItem: {
      readonly id: string;
    };
  };
};
export type DeviceScheduleScreen_UpdateMutation = {
  response: DeviceScheduleScreen_UpdateMutation$data;
  variables: DeviceScheduleScreen_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceScheduleItemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endsAt"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "playlistId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "screenConfigId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startsAt"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceScheduleItemId",
        "variableName": "deviceScheduleItemId"
      },
      {
        "kind": "Variable",
        "name": "endsAt",
        "variableName": "endsAt"
      },
      {
        "kind": "Variable",
        "name": "mode",
        "variableName": "mode"
      },
      {
        "kind": "Variable",
        "name": "playlistId",
        "variableName": "playlistId"
      },
      {
        "kind": "Variable",
        "name": "screenConfigId",
        "variableName": "screenConfigId"
      },
      {
        "kind": "Variable",
        "name": "startsAt",
        "variableName": "startsAt"
      }
    ],
    "concreteType": "UpdateDeviceScheduleItemPayload",
    "kind": "LinkedField",
    "name": "updateDeviceScheduleItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceScheduleItem",
        "kind": "LinkedField",
        "name": "deviceScheduleItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceScheduleScreen_UpdateMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeviceScheduleScreen_UpdateMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "69816206646aae430e3841d128b21682",
    "id": null,
    "metadata": {},
    "name": "DeviceScheduleScreen_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation DeviceScheduleScreen_UpdateMutation(\n  $deviceScheduleItemId: ID!\n  $startsAt: ISO8601DateTime!\n  $endsAt: ISO8601DateTime!\n  $mode: DeviceScheduleItemMode!\n  $screenConfigId: ID\n  $playlistId: ID\n) {\n  updateDeviceScheduleItem(deviceScheduleItemId: $deviceScheduleItemId, startsAt: $startsAt, endsAt: $endsAt, mode: $mode, screenConfigId: $screenConfigId, playlistId: $playlistId) {\n    deviceScheduleItem {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d438ec17d6befa6fb0afcd5a54fd654";

export default node;
