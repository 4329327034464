import React, { Suspense, useEffect } from "react";
import { ID, ISO8601DateTime, TimeZone } from "../helpers/types";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { graphql, useLazyLoadQuery } from "react-relay";
import { ScheduleConflictChecker_ConflictCheckerQuery } from "./__generated__/ScheduleConflictChecker_ConflictCheckerQuery.graphql";
import { inUtc } from "../helpers/datetime";

export type ScheduleConflictStatus = {
  status: "loading" | "ready";
  conflicts: { id: ID }[];
};

type ScheduleConflictCheckerProps = {
  deviceScheduleId: ID;
  excludedDeviceScheduleItemIds: ID[];
  startsAt: ISO8601DateTime;
  endsAt: ISO8601DateTime;
  timezone: TimeZone;
  value: ScheduleConflictStatus;
  onChange: (status: ScheduleConflictStatus) => void;
};

export const ScheduleConflictChecker = (
  props: ScheduleConflictCheckerProps,
) => {
  return (
    <Suspense fallback={<ConflictCheckerLoading onChange={props.onChange} />}>
      <ConflictCheckerInner {...props} />
    </Suspense>
  );
};

const ConflictCheckerLoading = (props: {
  onChange: (status: ScheduleConflictStatus) => void;
}) => {
  const { t } = useTranslation("ScheduleConflictChecker");

  useEffect(() => {
    props.onChange({ status: "loading", conflicts: [] });
  }, [props]);

  return <Alert severity="info">{t("Checking for conflicts")}</Alert>;
};

const ConflictCheckerInner = ({
  deviceScheduleId,
  excludedDeviceScheduleItemIds,
  startsAt,
  endsAt,
  timezone,
  value,
  onChange,
}: ScheduleConflictCheckerProps) => {
  const { t } = useTranslation("ScheduleConflictChecker");
  const { node: deviceSchedule } =
    useLazyLoadQuery<ScheduleConflictChecker_ConflictCheckerQuery>(
      graphql`
        query ScheduleConflictChecker_ConflictCheckerQuery(
          $deviceScheduleId: ID!
          $from: ISO8601DateTime!
          $to: ISO8601DateTime!
          $excludedDeviceScheduleItemIds: [ID!]
        ) {
          node(id: $deviceScheduleId) {
            __typename
            ... on DeviceSchedule {
              deviceScheduleItems(
                from: $from
                to: $to
                excludedDeviceScheduleItemIds: $excludedDeviceScheduleItemIds
              ) {
                nodes {
                  id
                }
              }
            }
          }
        }
      `,
      {
        deviceScheduleId,
        from: inUtc(startsAt, timezone).add(1, "second").toISOString(),
        to: inUtc(endsAt, timezone).subtract(1, "second").toISOString(),
        excludedDeviceScheduleItemIds,
      },
      {
        fetchPolicy: "network-only",
      },
    );

  if (deviceSchedule?.__typename !== "DeviceSchedule") {
    throw new Error("Device schedule not found");
  }

  useEffect(() => {
    onChange({
      status: "ready",
      conflicts: deviceSchedule.deviceScheduleItems.nodes.slice(),
    });
  }, [deviceSchedule, onChange]);

  if (value.conflicts.length === 0) {
    return <Alert severity="success">{t("No conflicts found")}</Alert>;
  }

  return (
    <Alert severity="error">
      {t(`{{count}} conflict found`, { count: value.conflicts.length })}
    </Alert>
  );
};
