/**
 * @generated SignedSource<<1cedbeb2284e1343c277f3b098038211>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceScheduleItemMode = "config" | "playlist" | "%future added value";
export type DeviceScheduleScreen_EventsQuery$variables = {
  deviceScheduleId: string;
  from: string;
  skip: boolean;
  to: string;
};
export type DeviceScheduleScreen_EventsQuery$data = {
  readonly node?: {
    readonly __typename: "DeviceSchedule";
    readonly deviceScheduleItems: {
      readonly nodes: ReadonlyArray<{
        readonly endsAt: string;
        readonly id: string;
        readonly mode: DeviceScheduleItemMode;
        readonly playlist: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
        readonly screenConfig: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
        readonly startsAt: string;
      }>;
    };
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type DeviceScheduleScreen_EventsQuery = {
  response: DeviceScheduleScreen_EventsQuery$data;
  variables: DeviceScheduleScreen_EventsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceScheduleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceScheduleId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "from",
      "variableName": "from"
    },
    {
      "kind": "Variable",
      "name": "to",
      "variableName": "to"
    }
  ],
  "concreteType": "DeviceScheduleItemConnection",
  "kind": "LinkedField",
  "name": "deviceScheduleItems",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceScheduleItem",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Playlist",
          "kind": "LinkedField",
          "name": "playlist",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ScreenConfig",
          "kind": "LinkedField",
          "name": "screenConfig",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceScheduleScreen_EventsQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/)
                ],
                "type": "DeviceSchedule",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeviceScheduleScreen_EventsQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "DeviceSchedule",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0cd555a223296b0deefff7809ce44d4e",
    "id": null,
    "metadata": {},
    "name": "DeviceScheduleScreen_EventsQuery",
    "operationKind": "query",
    "text": "query DeviceScheduleScreen_EventsQuery(\n  $deviceScheduleId: ID!\n  $from: ISO8601DateTime!\n  $to: ISO8601DateTime!\n  $skip: Boolean!\n) {\n  node(id: $deviceScheduleId) @skip(if: $skip) {\n    __typename\n    ... on DeviceSchedule {\n      id\n      deviceScheduleItems(from: $from, to: $to) {\n        nodes {\n          id\n          startsAt\n          endsAt\n          mode\n          playlist {\n            id\n            name\n          }\n          screenConfig {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b69ca455016cc41b81ac39c6e05cf2dd";

export default node;
