import { format, isSameMonth, isSameYear } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { mapValues } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { english } from "../localisations/en";
import { dateFnsLocales, LocaleName, Localisation } from "../helpers/localise";

/** This type is intentionally not exported. Don't add new format strings, use the existing ones */
type DateTimeFormat = string;

export function formatDateTime(
  date: Date,
  formatString: DateTimeFormat,
  locale: LocaleName,
  timeZone?: string | null,
): string {
  const options = { locale: dateFnsLocales[locale] ?? dateFnsLocales.en };
  try {
    if (timeZone != null) {
      return formatInTimeZone(date, timeZone, formatString, options);
    }
    return format(date, formatString, options);
  } catch {
    return "-";
  }
}

type DateTimeFormatName = keyof Localisation["dateFormats"];

export type DateTimeFormatter = (
  date: Date,
  timeZone?: string | null,
) => string;
export type DateTimeRangeFormatter = (
  start: Date,
  end: Date,
  timeZone?: string | null,
) => string;

type RangePrecision = "dayMonthYear";

type RangeFormatters = {
  [precision in RangePrecision]: DateTimeRangeFormatter;
};

export type DateFormatter = {
  [format in DateTimeFormatName]: DateTimeFormatter;
} & {
  range: RangeFormatters;
};

export function useDateFormatter(): DateFormatter {
  const { t, i18n } = useTranslation("dateFormats");
  return useMemo((): DateFormatter => {
    const rangeFormatters: RangeFormatters = {
      dayMonthYear(startDate: Date, endDate: Date, timeZone): string {
        const starts = formatDateTime(
          startDate,
          !isSameYear(startDate, endDate)
            ? (t("dayMonthYearFormat") as DateTimeFormat)
            : !isSameMonth(startDate, endDate)
              ? (t("dayMonthFormat") as DateTimeFormat)
              : (t("dayFormat") as DateTimeFormat),
          i18n.language as LocaleName,
          timeZone,
        );
        const ends = formatDateTime(
          endDate,
          t("dayMonthYearFormat") as DateTimeFormat,
          i18n.language as LocaleName,
          timeZone,
        );
        return `${starts} - ${ends}`;
      },
    };
    const dateTimeFormatters = mapValues(
      english.dateFormats,
      (_, format: string): DateTimeFormatter => {
        return (date, timeZone): string =>
          formatDateTime(
            date,
            t(format as DateTimeFormatName) as DateTimeFormat,
            i18n.language as LocaleName,
            timeZone,
          );
      },
    );
    return {
      ...dateTimeFormatters,
      range: rangeFormatters,
    };
  }, [t, i18n]);
}
