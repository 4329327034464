import React from "react";
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AppText } from "../Typography";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { TimeZone } from "../../helpers/types";
import { Public } from "@mui/icons-material";
import { Spacer } from "../Spacer";
import { Row } from "../Flex";
import { colors } from "../../helpers/theme";
import { useTranslation } from "react-i18next";

export const TimeZonePicker = ({
  value,
  onChange,
  sx,
  ...props
}: Omit<FormControlProps, "onChange"> & {
  value: TimeZone;
  onChange: (value: TimeZone) => void;
}) => {
  const { t } = useTranslation("TimeZonePicker");
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    ...allTimezones,
  });

  return (
    <FormControl
      sx={{ width: "100%", backgroundColor: colors.white, ...sx }}
      {...props}
    >
      <InputLabel>{t("Timezone")}</InputLabel>
      <Select
        onChange={(ev) => {
          onChange(parseTimezone(ev.target.value).value as TimeZone);
        }}
        label={t("Timezone")}
        value={value}
        startAdornment={
          <Row>
            <Public />
            <Spacer size="smallest" />
          </Row>
        }
      >
        {options.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            <AppText>{option.label}</AppText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
