import React from "react";
import { Tab, TabProps, Tabs } from "@mui/material";
import { Row } from "./Flex";
import { colors, spacing } from "../helpers/theme";
import { useMatch, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import classNames from "classnames";

export type TabGroupProps = { tabs: TabProps[] };

const useStyles = makeStyles({
  tabs: {
    "& > .Mui-disabled": {
      opacity: 0.2,
    },
    // if the tabs does not have the scroll arrows
    "&:has(> :last-child:nth-child(2))": {
      marginLeft: spacing.small,
    },
  },
  tabsMedium: {
    // if the tabs does not have the scroll arrows
    "&:has(> :last-child:nth-child(2))": {
      marginLeft: spacing.medium,
    },
  },
});

export const TabGroup = (props: TabGroupProps) => {
  const navigator = useNavigate();
  const match = useMatch("/*");
  const currentTab =
    match?.params?.["*"] == "" ? "devices" : match?.params?.["*"];
  const screenSize = useScreenSize();
  const classes = useStyles();

  return (
    <Row
      style={{
        borderBottom: `1px solid ${colors.gray4}`,
      }}
    >
      <Tabs
        indicatorColor="primary"
        onChange={(_ev, value) => navigator(`/${value}`)}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        className={classNames(
          classes.tabs,
          screenSize === "sm" ? undefined : classes.tabsMedium,
        )}
      >
        {props.tabs.map((tab, id) =>
          tab.hidden ? null : <Tab key={id} {...tab} />,
        )}
      </Tabs>
    </Row>
  );
};
