/**
 * @generated SignedSource<<b23160922c459871e68611bd041a3068>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceScheduleScreen_DeviceQuery$variables = {
  deviceId: string;
};
export type DeviceScheduleScreen_DeviceQuery$data = {
  readonly node: {
    readonly __typename: "ScreenDevice";
    readonly deviceSchedule: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DeviceScheduleScreen_DeviceFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type DeviceScheduleScreen_DeviceQuery = {
  response: DeviceScheduleScreen_DeviceQuery$data;
  variables: DeviceScheduleScreen_DeviceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceSchedule",
  "kind": "LinkedField",
  "name": "deviceSchedule",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceScheduleScreen_DeviceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeviceScheduleScreen_DeviceFragment"
              }
            ],
            "type": "ScreenDevice",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceScheduleScreen_DeviceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeZone",
                "storageKey": null
              }
            ],
            "type": "ScreenDevice",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b09373608bbcc92be96996e4532d8664",
    "id": null,
    "metadata": {},
    "name": "DeviceScheduleScreen_DeviceQuery",
    "operationKind": "query",
    "text": "query DeviceScheduleScreen_DeviceQuery(\n  $deviceId: ID!\n) {\n  node(id: $deviceId) {\n    __typename\n    ... on ScreenDevice {\n      id\n      deviceSchedule {\n        id\n      }\n      ...DeviceScheduleScreen_DeviceFragment\n    }\n    id\n  }\n}\n\nfragment DeviceScheduleScreen_DeviceFragment on ScreenDevice {\n  id\n  deviceName\n  timeZone\n  deviceSchedule {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6058e614792688c403b6b0a8677cb318";

export default node;
