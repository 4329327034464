import React, { Suspense, useState } from "react";
import { IconButton, MenuItem } from "@mui/material";
import { ID } from "../../helpers/types";
import * as yup from "yup";
import { Close, SubscriptionsRounded } from "@mui/icons-material";
import { IconAdornment } from "../IconAdornment";
import { Column } from "../Flex";
import { useDebounced } from "../../hooks/useDebounced";
import { AppText, HelperText } from "../Typography";
import { LoadingSpinner } from "../LoadingSpinner";
import { Spacer } from "../Spacer";
import { InfiniteScrollMenuItem } from "../InfiniteScroll";
import { Tooltip } from "../Tooltip";
import { useFetchKey } from "../../providers/RelayProvider";
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { PlaylistPicker_Playlists } from "./__generated__/PlaylistPicker_Playlists.graphql";
import { PlaylistPickerQuery } from "./__generated__/PlaylistPickerQuery.graphql";
import { PlaylistPicker_Playlists_Organisation$key } from "./__generated__/PlaylistPicker_Playlists_Organisation.graphql";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { TextInput } from "../TextInput";
import { InputDropdown } from "../InputDropdown";

const pageSize = 20;

export type Playlist = {
  id: ID;
  name: string;
};

export const playlistPickerValidator: yup.SchemaOf<Playlist> = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
});

type PlaylistPickerProps = {
  value: Playlist | null;
  onChange: (value: Playlist | null) => void;
  error?: boolean;
};

export const PlaylistPicker = (props: PlaylistPickerProps) => {
  const { t } = useTranslation("PlaylistPicker");
  const [searchText, setSearchText] = useState(props.value?.name ?? "");
  const debouncedSearchText = useDebounced(searchText, 300);
  const [focused, setFocused] = useState(false);

  const handleClear = () => {
    props.onChange(null);
    setSearchText("");
  };

  return (
    <Column style={{ position: "relative" }}>
      <TextInput
        maxLength={100}
        label={t("Playlist")}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        value={props.value == null ? searchText : props.value.name}
        onChange={(ev) => {
          props.onChange(null);
          setSearchText(ev.target.value);
        }}
        error={props.error}
        InputProps={{
          onKeyDown: (ev) => {
            if (props.value == null || ev.key === "Escape") return;
            if (ev.key === "Backspace") handleClear();
            ev.preventDefault();
            ev.stopPropagation();
          },
          startAdornment: (
            <IconAdornment>
              <SubscriptionsRounded />
            </IconAdornment>
          ),
          endAdornment:
            props.value == null && searchText.length === 0 ? undefined : (
              <Tooltip placement="left" title={t("Clear playlist")}>
                <IconButton onClick={() => handleClear()}>
                  <Close />
                </IconButton>
              </Tooltip>
            ),
        }}
        placeholder={t("No playlist selected")}
      />

      {props.value == null && (focused || debouncedSearchText.length > 0) && (
        <InputDropdown>
          <Suspense
            fallback={
              <Column paddingVertical="smallest">
                <MenuItem>
                  <LoadingSpinner />
                  <Spacer size="smallest" />
                  <HelperText>{t("Searching playlists")}</HelperText>
                </MenuItem>
              </Column>
            }
          >
            <PlaylistList
              searchText={debouncedSearchText}
              onChange={(opt) => props.onChange(opt)}
            />
          </Suspense>
        </InputDropdown>
      )}
    </Column>
  );
};

const PlaylistList = (props: {
  searchText: string;
  onChange: (value: Playlist | null) => void;
}) => {
  const { t } = useTranslation("PlaylistPicker");
  const { currentOrganisation } = useLazyLoadQuery<PlaylistPickerQuery>(
    graphql`
      query PlaylistPickerQuery(
        $after: String
        $first: Int!
        $searchText: String
      ) {
        currentOrganisation {
          id
          ...PlaylistPicker_Playlists_Organisation
        }
      }
    `,
    {
      after: null,
      first: pageSize,
      searchText: isEmpty(props.searchText) ? null : props.searchText,
    },
    { fetchKey: useFetchKey("playlists") },
  );

  const {
    data: { playlists },
    hasNext,
    loadNext,
    isLoadingNext,
  } = usePaginationFragment<
    PlaylistPicker_Playlists,
    PlaylistPicker_Playlists_Organisation$key
  >(
    graphql`
      fragment PlaylistPicker_Playlists_Organisation on Organisation
      @refetchable(queryName: "PlaylistPicker_Playlists") {
        playlists(first: $first, after: $after, searchText: $searchText)
          @connection(key: "PlaylistPicker_playlists") {
          edges {
            cursor
            node {
              id
              name
            }
          }
        }
      }
    `,
    currentOrganisation,
  );

  if (playlists.edges.length === 0) {
    return (
      <Column paddingVertical="smallest">
        <MenuItem>
          <HelperText>
            {props.searchText.trim().length > 0
              ? t("No playlists found for '{{searchText}}'", {
                  searchText: props.searchText,
                })
              : t("No playlists found")}
          </HelperText>
        </MenuItem>
      </Column>
    );
  }

  return (
    <Column paddingVertical="smallest">
      {playlists.edges.map(({ node: opt }) => (
        <MenuItem
          key={opt.id}
          onMouseDown={() => props.onChange(opt)}
          value={opt.id}
        >
          <Column>
            <AppText>{opt.name}</AppText>
          </Column>
        </MenuItem>
      ))}
      <InfiniteScrollMenuItem
        hasNext={hasNext}
        loadNext={() => loadNext(pageSize)}
        isLoadingNext={isLoadingNext}
      />
    </Column>
  );
};
